import axios from 'axios';

const orgEnv = {
    prod: process.env.REACT_APP_BASEURL_PROD_API,
    dev: process.env.REACT_APP_BASEURL_DEV_API
  };
  let currentEnv;
  if (
    window.location.host.includes("amazon") ||
    window.location.host.includes("localhost") ||
    window.location.host.includes("shapla")
  
  ) {
    currentEnv = "dev";
  } else {
    currentEnv = "prod";
  }
  const orgBaseUrl = process.env.REACT_APP_Environment === 'dev' ? process.env.REACT_APP_BASEURL_DEV_API : process.env.REACT_APP_BASEURL_PROD_API;
  console.log('Base URL ' +orgBaseUrl)

export function getGuestById(id){
    console.log("GuestID: ", id)
    return new Promise((resolve, reject) => {
      axios
        .get(`${orgBaseUrl}/invite-guest/${id}` , {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then((res) => {
          resolve(res);
          
        })
        .catch((error) => {
          reject(error);
        });
    });

}

export function updateGuestById(id, obj){

    return new Promise((resolve, reject) => {
      axios
        .put(`${orgBaseUrl}/invite-guest/${id}` , obj , {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });

}

export function sendEmailVerificationCode(id, obj){
    
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/invite-guest/send-verificaition-email/${id}` , obj , {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });

}

export function sendPhoneVerificationCode(id, obj){
    
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/invite-guest/send-verificaition-sms/${id}` , obj , {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });

}

export function verifyEmail(id, obj){
    
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/invite-guest/verify-email-code/${id}` , obj , {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });

}

export function verifyPhone(id, obj){
    
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/invite-guest/verify-sms-code/${id}` , obj , {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });

}

export function makePrimaryApi(id, obj){
    
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/invite-guest/make-primary/${id}` , obj , {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });

}