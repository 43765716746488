import React, {useEffect, useState} from 'react';
import Header from '../Header/Header';
import PageTitle from '../PageTitle/PageTitle';
import Stepper from '../Stepper/Stepper';
import FAQ from '../FAQ/FAQ';
import Footer from '../Footer/Footer';
import { makeStyles } from '@material-ui/core/styles';
import classes from './form.module.css';
import {getGuestById} from '../Stepper/api';


function Form(props) {
    //const classes = useStyles();

    const [speakerData, setSpeakerData] = useState({});
    const [notification, setNotification] = useState('');

    useEffect(() => {
        const getData = async () => {
            const url = window.location.href;
            const guestId  = url.substring(url.lastIndexOf("/") + 1);
            console.log("guestID: ", guestId)

            if(guestId.length > 0) {
                try {
                    const guestData = await getGuestById(guestId);
                    console.log("guestData: ", guestData)
                    setSpeakerData(guestData);
                } catch(err) {
                    console.log("Error is: ", err);
                }
            }
        }
        getData();
    }, []);


    const showNotificaton = (value) => {
        setNotification(value);

        setTimeout(() => {
            setNotification('');
        }, 5000)

    }

    const alertHide = () => {
        setNotification('');
    }
    return (

        <div className={classes.root}>
           <Header notification = {notification} alertHide = {alertHide}/>
           <PageTitle />
           <Stepper speaker = {speakerData} showNotificaton = {showNotificaton}/>
           <FAQ />
           <Footer speaker = {speakerData} />
        </div >
    );
}
export default Form;



// const useStyles = makeStyles((theme) => ({

//     root:{
        
//     },

   


// }));