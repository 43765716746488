import React from 'react';
import './pagetitle.css';




function PageTitle(props) {

    return (

        <div className="PageTitleMianCintaier">Accept Invitation</div>
    );
}
export default PageTitle;

