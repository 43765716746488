import React, {useEffect, useState} from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

import GeneralInfo from '../GeneralInfo/GeneralInfo'
import ContactDetails from '../ContactDetails/ContactDetails'
import InviteAccepted from '../InviteAccepted/InviteAccepted'
import './Stepper.css';
import {getGuestById, updateGuestById} from './api';

function PageTitle(props) {

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const [guestData, setGuestData] = useState({});
    const [error, setError] = useState(false);
    const [guestId, setGuestId] = useState('');
    const steps = getSteps();
    useEffect(() => {
        const getData = async () => {
            const url = window.location.href;
            const guestId  = url.substring(url.lastIndexOf("/") + 1);
            console.log("guestId: ", guestId)
            if(guestId.length > 0) {
                try {
                    const guestData = await getGuestById(guestId);
                    setGuestId(guestId);
                    updateState(guestData, false);
                } catch(error) {
                    setError(true);
                }
                
            } else {
                setError(true);
            }
        }
        getData();
    }, []);

    const updateState = (tempGuestData, flag) => {
        console.log("updateState Called: ", flag, tempGuestData);
        if(tempGuestData?.data?.body?.data && Object.keys(tempGuestData.data.body.data).length > 0) {
            if(flag) {
                const tempData = tempGuestData.data.body.data;
                delete tempData.orgId;
                const newData = {
                    ...guestData,
                    phone: tempData.phone,
                    emailAddress: tempData.emailAddress,
                    phoneVerified: tempData.phoneVerified,
                    emailVerified: tempData.emailVerified,
                    primary: tempData.primary
                }
                setGuestData(newData);
            } else {
                setGuestData(tempGuestData.data.body.data);
            }
            // console.log("guestData.data.body.data: ", guestData.data.body.data); 
             // setGuestId(guestId)
          } else {
              setError(true);
          }
    }

    const onGuestdataChange = (value, field) => {
        switch(field) {
            case 'firstName':
            case 'lastName':
                setGuestData({
                    ...guestData,
                    name: {
                        ...guestData.name,
                        [field]: value
                    }
                });
            break;

            case 'designation':
                setGuestData({
                    ...guestData,
                    designation: {
                        title: value
                    }
                });
            break;

            case 'image':
                setGuestData({
                    ...guestData,
                    imageMetaData: {
                        ...guestData.imageMetaData,
                        imageName: value.title,
                        imageUrl: value.src

                    }
                });
            break;


            default:
                setGuestData({
                    ...guestData,
                    [field]: value
                })
                break;
        }
    }

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const updateGuestData = async() => {
        if(guestId) {
            try {
                const obj = {
                    ...guestData,
                    // status: 'Accepted'
                }
                
                console.log("obj: ", obj)
                await updateGuestById(guestId, obj)
            } catch(err) {
                console.log("Error in updateGuestData: ", err);
                setError(true)
            }
        }
    }

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setCompleted({});
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        if(completedSteps() === totalSteps() - 1) {
            updateGuestData();
        }
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    function getSteps() {
        return ['General Info', 'Contact Details',];
    }




    function getStepContent(step) {
        switch (step) {
            case 0:
                return (<GeneralInfo guestData = {guestData} onChange = {(e, field) => onGuestdataChange(e, field)}/>);
            case 1:
                return (<ContactDetails guestData = {guestData} newState = {(data, flag) => updateState(data, flag)} showNotificaton = {props.showNotificaton}/>);
            default:
                return 'Unknown step';
        }
    }

    return (

        <div className="StapperMainContainer">

            <div className="StepperHeader">
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepButton onClick={handleStep(index)} completed={completed[index]}>
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            </div>


            <>
                {allStepsCompleted() ? (
                    <>
                        <InviteAccepted />

                        {/* <Button onClick={handleReset}>Reset</Button> */}
                    </>
                ) : (
                    <div className="FormBody">

                        <Typography className="instructions">{getStepContent(activeStep)}</Typography>

                        <div className="FormButtonBody">
                           
                            {activeStep !== 0 &&
                                <Button onClick={handleBack} className="buttonBack">
                                    <ArrowBackIosRoundedIcon /> GO BACK
                                </Button>
                            }
                             <span></span>
                            {/* <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.button}
                            >
                                Next
                            </Button> */}
                            {activeStep !== steps.length &&
                                (completed[activeStep] ? (
                                    <Typography variant="caption" className="completed">
                                        Step {activeStep + 1} already completed
                                    </Typography>
                                ) : (
                                    <Button variant="contained" color="primary" onClick={handleComplete}>
                                        {completedSteps() === totalSteps() - 1 ? 'ACCEPT INVITATION' : 'CONTINUE'}
                                    </Button>
                                ))}
                        </div>
                    </div>
                )}
            </>
        </div>
    );
}
export default PageTitle;

