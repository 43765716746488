import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import { PhoneInputField, CheckBox, } from "master-react-lib";
import { ModalPopup, SimpleOtp, TextInputFixedLabel } from "@dclab/dc-react-ui-kit";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import './contactdetails.css';
import {sendEmailVerificationCode, sendPhoneVerificationCode, verifyEmail, verifyPhone, makePrimaryApi} from '../Stepper/api';



function ContactDetails(props) {


    const [phone, setPhone] = useState({
        country: "",
        number: "",
    });
    const [email, setEmail] = useState('');
    const [value, setvalue] = React.useState('');
    const [valueOtpPhoneCode, SetvalueOtpPhoneCode] = React.useState('');
    const [valueOtpEmailCode, SetvalueOtpEmailCode] = React.useState('');
    const [open, setOpenVerifyemail] = useState(false);
    const [openChangeEmail, SetOpenChangeEmail] = useState(false);
    const [OpenChangePhone, SetOpenChangePhone] = useState(false);
    const [VerifyPhone, SetVerifyPhone] = useState(false);
    const [guestId, setGuestId] = useState('');
    const [phoneVerified, setPhoneVerified] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const [makePrimary, setMakePrimary] = useState('');
    const [tempEmail, setTempEmail] = useState('');
    const [tempPhone, setTempPhone] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
 
    useEffect(() => {
        if(props.guestData && Object.keys(props.guestData).length > 0) {
            const {phone, emailAddress, _id, phoneVerified, emailVerified, primary} = props.guestData;
            
            setPhone({
                number: phone
            });
            setEmail(emailAddress);
            setTempEmail(emailAddress);
            setTempPhone(phone);
            setGuestId(_id);
            if(phoneVerified)
                setPhoneVerified(phoneVerified);
            if(emailVerified)
                setEmailVerified(emailVerified);

            if(primary) 
                setMakePrimary(primary)
        }
    }, [])

    const click = (e) => {
        e.preventDefault();
        console.log(`verify`);
    };
    const resend = () => {
        console.log(`resend`);
    };
    const onBlurAction = () => {
        console.log('onBlurAction')
    }

    const onClickEmailResend = async () => {
        if(tempEmail && tempEmail.length > 0) {
            const obj = {
                emailAddress: tempEmail
            }
            try {

                await sendEmailVerificationCode(guestId, obj);
                props.showNotificaton('Email');
            } catch(err) {
                console.log("Error Occured: ", err)
            }
            // if(!verification.data.error) {
            //     SetOpenChangeEmail(false)
            //     setOpenVerifyemail(true);
            // }
        }
    }

    const onClickSmsResend = async () => {
        if(tempPhone && tempPhone.length > 0) {
            setTempPhone(phoneValue);
            const obj = {
                phone: tempPhone
            }
            try {

                await sendPhoneVerificationCode(guestId, obj);
                props.showNotificaton('Sms');
            } catch(err) {
                console.log("Error Occured: ", err)
            }
            // if(!verification.data.error) {
            //     SetOpenChangePhone(false)
            //     SetVerifyPhone(true);
            // }
        } 
    }




    //--------------- Modal Email Verification CODE Start----------------//
   

    const handleOpen = () => {
        setOpenVerifyemail(true);
    };

    const handleClose = () => {
        console.log('close')
        setOpenVerifyemail(false)
    };


    const handleConfirm = async() => {
        if(guestId && guestId.length > 0 && valueOtpEmailCode.length === 6) {
            try {
                const obj = {
                    code: valueOtpEmailCode,
                    newField: tempEmail !== email
                }
                const verificaition = await verifyEmail(guestId, obj);
                setOpenVerifyemail(false);
                if(!verificaition.data.error)
                    props.newState(verificaition, true)
                    setEmail(tempEmail)
                    SetvalueOtpEmailCode('');
                    setEmailVerified(true);
            } catch(error) {
                setOpenVerifyemail(false);

                console.log(error);
            }
            setvalue('');
        }


        // const verificaition = await verifyEmail()
       
        // setOpenVerifyemail(false)
    };
    //--------------- Modal Email Verification CODE End-------------------//

    //--------------------- Modal Change Email Start---------------------//

    const handleOpenChangeEmail = () => {
        SetOpenChangeEmail(true);
    };

    const handleClose2 = () => {
        console.log('close')
        SetOpenChangeEmail(false)
    };


    const handleConfirmChangeEmail = async () => {
        setTempEmail(value);

        if(value && value.length > 0) {
            const obj = {
                newEmail: true,
                emailAddress: value
            }
            const verification = await sendEmailVerificationCode(guestId, obj);
            if(!verification.data.error) {
                SetOpenChangeEmail(false)
                setOpenVerifyemail(true);
            }
        }
     
    };
    //--------------------- Modal Change Email End---------------------//

    //--------------------- Modal Change Phone Start---------------------//

    const handleOpenChangePhone = () => {
        SetOpenChangePhone(true);
    };

    const handleClose3 = () => {
        console.log('close')
        SetOpenChangePhone(false)
    };


    const ChangePhoneConfirm = async () => {
        
        if(phoneValue && phoneValue.length > 0) {
            setTempPhone(phoneValue);
            const obj = {
                newPhone: true,
                phone: phoneValue
            }
            const verification = await sendPhoneVerificationCode(guestId, obj);
            if(!verification.data.error) {
                SetOpenChangePhone(false)
                SetVerifyPhone(true);
            }
        }    
    };
    //--------------------- Modal Change Phone End---------------------//

    //--------------------- Modal Change Phone Start---------------------//

    const handleOpenVerifyPhone= () => {
        SetVerifyPhone(true);
    };

    const handleClose4 = () => {
        console.log('close')
        SetVerifyPhone(false)
    };


    const verifyPhoneConfirm = async () => {
        if(guestId && guestId.length > 0 && valueOtpPhoneCode.length === 6) {
            try {
                const obj = {
                    code: valueOtpPhoneCode,
                    newField: phone.number !== tempPhone 
                }
                const verificaition = await verifyPhone(guestId, obj);
                if(!verificaition.data.error) {
                    props.newState(verificaition, true);
                    setPhone({
                        number: tempPhone
                    });
                    SetvalueOtpPhoneCode('');
                    setPhoneVerified(true);
                }
            } catch(error) {
                console.log(error);
            }
        
        }
        console.log('confirm')
        SetVerifyPhone(false)
    };
    //--------------------- Modal Change Phone End---------------------//


    const onVerificationEmailClicked = async () => {
        setOpenVerifyemail(true);
      
        if(email && email.length > 0) {
            const obj = {
                emailAddress: email,
                newField: value.length > 0 ? true : false
            }
            const verification = await sendEmailVerificationCode(guestId, obj);
            if(!verification.data.error) {
                setvalue('');
               props.newState(verification, true);
            }
        }

    }
    
    const onVerificationSmsClicked = async () => {
        SetVerifyPhone(true);
        if(phone.number && phone.number.length > 0) {
            const verification = await sendPhoneVerificationCode(guestId, {phone: phone.number});
            // if(!verification.data.error) {
            //    props.newState(verification)
            // }
        }
    }

    const setprimary = async (field) => {
        if(field === 'phone' && !phoneVerified) {
           
            return;
        }
        if(field === 'email' && !emailVerified) {
          
            return;
        }
        try {
            const response = await makePrimaryApi(guestId, {field});
            if(!response.data.error) {
                setMakePrimary(field);
                props.newState(response, true);
            }
        } catch (error) {
            console.log(error);
        }
       
    }
   

    return (

        <div className="ContactDetailsMainContainer">


            <Grid container spacing={2}>

                <Grid item md={12} item sm={12} item xs={12}>
                    <>
                    <PhoneInputField
                        phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                        country={'us'}
                        value={phone.number}
                        countryCodeEditable={false}
                        // onChange={(e, f, g, h) => props.onChange(h, 'phone')}
                        onChange={(e, f, g, h) => {}}
                        disabled = {true}
                        onBlur={() => console.log("onBlur")}
                        onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                        variant="outlined"
                        // onlyCountries={["us", "ca", "gb", "my"]}
                        containerStyle={{}}
                        buttonStyle={{}}
                        inputStyle={{
                            marginLeft: "0px",
                            height: "40px",
                            width: "100%",
                            fontFamily: "Roboto-Regular",
                            fontSize: "16px",
                        }}
                        containerClass={{}}
                        errorText={"Phone number is required"}
                        error={false}
                        errorStyles={{ marginBottom: "5px" }}
                        autoFormat={true}
                        disableDropdown={false}
                        disabled={true}
                        disableCountryCode={false}
                        enableSearch={false}
                        disableSearchIcon={false}
                        placeholder={""}
                        inputProps={{}}
                        //specialLabel={'Primary Phone'}
                        // preferredCountries={[]}
                        // excludeCountries={[]}
                        labelFixed="Phone number"
                    />
                    </>

                    <div  className="verifiedContainer">
                       {phoneVerified &&
                         <div  className="chip"><CheckCircleRoundedIcon className="chipIcon" /> Verified</div >
                       }
                        {phoneVerified && (makePrimary?.length === 0 || makePrimary !== 'phone') && <Link className="Link" onClick={() => setprimary('phone')}>Make Primary</Link>}
                    </div>

                    <div  className="verifiedContainer" >
                    {!phoneVerified && <Button className="vrifyButton" variant="outlined" href="#outlined-buttons" onClick={() => onVerificationSmsClicked()} >Verify Phone Number</Button> }
                    {!phoneVerified && <Link className="LinkBlack" href="#" onClick={() => SetOpenChangePhone(true)} >Change Phone Number</Link>}
                    </div>

                </Grid>

                <Grid item md={12} item sm={12} item xs={12}>
                    <div className="devider" ></div>
                </Grid>

                <Grid item md={12} item sm={12} item xs={12}>

                    <TextInputFixedLabel
                        label="Email address"
                        labelStatus={true}
                        placeholder="Email address"
                        Errormessage="Error message"
                        errortextStatus={false}
                        tooltipStatus={true}
                        //onChange={(e) => props.onChange(e.target.value, 'emailAddress')}
                        value={email}
                        disabled= {true}
                    />
                    <div  className="verifiedContainer">
                       {emailVerified &&
                         <div  className="chip"><CheckCircleRoundedIcon className="chipIcon" /> Verified</div >
                       }
                        {emailVerified && (makePrimary?.length === 0 || makePrimary !== 'email') && <span className="Link" onClick={() => setprimary('email')}>Make Primary</span>}
                    </div>

                    <div  className="verifiedContainer" >
                    {!emailVerified && <Button className="vrifyButton" variant="outlined" href="#outlined-buttons" onClick={() =>  onVerificationEmailClicked()}>Verify Email</Button>}
                    {!emailVerified &&   <Link  className="LinkBlack" href="#" onClick={() => SetOpenChangeEmail(true)} >Change Email</Link>}
                    </div>

                </Grid>

                <Grid item md={12} item sm={12} item xs={12}>
                    <div className="devider"></div>
                </Grid>

                <Grid item md={12} item sm={12} item xs={12}>
                    <CheckBox
                        label="Add my name for other organizations and Masajid to add me to events/programs."
                        value=""
                    />
                </Grid>

            </Grid>


            <ModalPopup
                ModalTitle="Verify your email address"
                handleConfirm={() => handleConfirm()}
                handleClose={() => handleClose()}
                handleClose={handleClose}
                open={open}
                LabeClose="CLOSE"
                LabeConfirm="CONFIRM"
                width="540px"

                ModalBody={
                    <div>
                        <SimpleOtp
                            name="verifyCode"
                            //title="Verification"
                            metaTitle={`We just sent an email to ${tempEmail} with a code. Enter it to verify your email address.`}
                            //email="hk2602809@gmail.com"
                            btnText="Verify"
                            forVerify={true}
                            onBlur={() => onBlurAction()}
                            value={valueOtpEmailCode}
                            onChangeCode={(char) => SetvalueOtpEmailCode(char)}
                            onChangeText={(e) => SetvalueOtpEmailCode(e.target.value)}
                            onClickVerify={(e) => click(e)}
                            onClickResend={onClickEmailResend}
                            hasErrored={false}
                            errorStyle={{
                                border: "1px solid #B00020",
                            }}
                            error={false}
                            textfieldvalue={value}
                            required={true}
                            id={"useremail"}
                            label={"Email Address"}
                            placeholder={"email"}
                            type={"email"}
                            error={false}
                            helperText={""}

                            ResendHelperText="Didn't receive code yet?"
                            ResendCode="Send again"
                            ButtonStatus={false}
                        />
                    </div>
                }
            />


            <ModalPopup
                ModalTitle="Change email address"
                handleConfirm={() => handleConfirmChangeEmail()}
                handleClose={() => handleClose2()}
                handleClose={handleClose2}
                open={openChangeEmail}
                LabeClose="CLOSE"
                LabeConfirm="CONTINUE"
                width="540px"

                ModalBody={
                    <div className="ModalBodyContainer">
                        <TextInputFixedLabel
                            label="Enter new email address"
                            labelStatus={true}
                            helpertext="We will send a verification code to your email address."
                            HelpertextStatus={true}
                            placeholder="Enter new email address"
                            Errormessage="Error message"
                            errortextStatus={false}
                            count={100}
                            countStatus={false}
                            tooltipStatus={true}
                            onChange={(e) => setvalue(e.target.value)}
                            value={value}
                        />
                    </div>
                }
            />


            <ModalPopup
                ModalTitle="Enter new phone number"
                handleConfirm={() => ChangePhoneConfirm()}
                handleClose={() => handleClose3()}
                handleClose={handleClose3}
                open={OpenChangePhone}
                LabeClose="CLOSE"
                LabeConfirm="CONTINUE"
                width="540px"

                ModalBody={
                    <div className="ModalBodyContainer">
                        <PhoneInputField
                            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                            country={'us'}
                            value={phoneValue}
                            countryCodeEditable={false}
                            // onChange={(phone, country) => console.log("onChange", phone, country)}
                            onChange={(e, f, g, h) => setPhoneValue(h)}

                            onBlur={() => console.log("onBlur")}
                            onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                            variant="outlined"
                            // onlyCountries={["us", "ca", "gb", "my"]}
                            containerStyle={{}}
                            buttonStyle={{}}
                            inputStyle={{
                                marginLeft: "0px",
                                height: "40px",
                                width: "100%",
                                fontFamily: "Roboto-Regular",
                                fontSize: "16px",
                            }}
                            containerClass={{}}
                            errorText={"Phone number is required"}
                            error={false}
                            errorStyles={{ marginBottom: "5px" }}
                            autoFormat={true}
                            disableDropdown={false}
                            disabled={false}
                            disableCountryCode={false}
                            enableSearch={false}
                            disableSearchIcon={false}
                            placeholder={""}
                            inputProps={{}}
                            //specialLabel={'Primary Phone'}
                            // preferredCountries={[]}
                            // excludeCountries={[]}
                            labelFixed="Enter new phone number"

                        />
                        <div className="helper_text">We will send a verification code to your phone number.</div>
                    </div>
                }
            />


            <ModalPopup
                ModalTitle="Verify your phone number"
                handleConfirm={() => verifyPhoneConfirm()}
                handleClose={() => handleClose4()}
                handleClose={handleClose4}
                open={VerifyPhone}
                LabeClose="CLOSE"
                LabeConfirm="CONFIRM"
                width="540px"

                ModalBody={
                    <div>
                        <SimpleOtp
                            name="verifyCode"
                            //title="Verification"
                            metaTitle={`We just sent an SMS to ${tempPhone} with a code. Enter it to verify your phone.`}
                            //email="hk2602809@gmail.com"
                            btnText="Verify"
                            forVerify={true}
                            onBlur={() => onBlurAction()}
                            value={valueOtpPhoneCode}
                            onChangeCode={(char) => SetvalueOtpPhoneCode(char)}
                            onChangeText={(e) => SetvalueOtpPhoneCode(e.target.value)}
                            onClickVerify={(e) => click(e)}
                            onClickResend={onClickSmsResend}
                            hasErrored={false}
                            errorStyle={{
                                border: "1px solid #B00020",
                            }}
                            error={false}
                            textfieldvalue={value}
                            required={true}
                            id={"useremail"}
                            label={"Email Address"}
                            placeholder={"email"}
                            type={"email"}
                            error={false}
                            helperText={""}

                            ResendHelperText="Didn't receive code yet?"
                            ResendCode="Send again"
                            ButtonStatus={false}
                        />
                    </div>
                }
            />


        </div >
    );
}
export default ContactDetails;



