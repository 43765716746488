import logo from './logo.svg';
import './App.css';

import Form from './components/Form/Form';

function App() {
  return (
    <div>
      <Form />
    </div>
  );
}

export default App;
