import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CallRoundedIcon from '@material-ui/icons/CallRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import { DcBottomFooter } from "@dclab/dc-react-ui-kit";
import DcLogo from '../../assets/DC_Logo.png';
import './footer.css';

function Footer(props) {

    const [orgData, setOrgData] = useState({});

    useEffect(() => {
        const getData = async () => {
        const {speaker} = props;
        if(speaker ) {
            const orgData = speaker?.data?.body?.data?.orgId ? speaker.data.body.data : {} ;
            if(Object.keys(orgData)) {
                const temp = {
                    email: orgData.orgEmail ?? '' ,
                    name: orgData.orgName ?? '',
                    phone: orgData.phoneFax ?? '',
                    location: orgData.orgAddress ?? {},
                    taxExempt: orgData?.taxIdStatus?.taxExempt ?? false,
                    taxId: orgData?.taxIdStatus?.taxIdEin ?? '',
                }
                setOrgData(temp);

                console.log("orgData: ", orgData)
            } 
        }
    }
    getData();
    }, [props.speaker]);
    

    return (
        <div>
            <div className="FooterMainContainer">

                <div className="FooterInnerContainer">
                    <Grid container spacing={3}>
                        {console.log("orgData is: ", orgData)}
                        <Grid item md={6} item sm={6} item xs={12}>
                            <div className="TitleExBold">{orgData.name}</div>
                            {orgData.taxExempt && <p className="Text"> We are 501(c)(3) tax-exempted organization.</p> }
                            {orgData?.taxId?.length > 0 && <p className="Text">Tax ID : {orgData.taxId} <CheckCircleRoundedIcon className="checkIcon" /></p>}
                        </Grid>

                        <Grid item md={3} item sm={3} item xs={12}>
                            <div className="Title">Address</div>
                            <p className="Text">{orgData?.location?.line1 ?? ''}</p>
                            <p className="Text">{orgData?.location?.city ?? ''}, {orgData?.location?.state ?? ''} {orgData?.location?.postalCode ?? ''}</p>
                        </Grid>

                        <Grid item md={3} item sm={3} item xs={12}>
                            <div className="Title">Contact</div>
                            <p className="Text"><CallRoundedIcon  className="contactIcon"/>{  orgData.phone }</p>
                            <p className="Text"><EmailRoundedIcon  className="contactIcon"/> <Link className="LinkText" href={`mailto:${orgData?.email}`} target="_blank">{orgData?.email}</Link></p>
                        </Grid>

                    </Grid>
                </div >

            </div >

            <DcBottomFooter 
                DcLogo={DcLogo}
            />

        </div >
    );
}
export default Footer;



