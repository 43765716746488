import React from 'react';
import Link from '@material-ui/core/Link';
import Logo from '../../assets/DC_Logo.png';
import './header.css';
import { AlertAction } from "@dclab/dc-react-ui-kit";



function Header(props) {

    return (
        <>
        {props?.notification?.length > 0 && <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                  <AlertAction
                    alert={props?.notification?.length > 0 ? props?.notification + ' sent' :  ''}
                    alertType={"success"}
                    onClick={() => props.alertHide()}
                  />
        </div>}

        <div className="HeaderMainContainer">
            <Link  className="LogoContainer" to="/"><img  className="HeaderLogo"   src={Logo} alt="DEENCONSOLE"/></Link>
        </div >
        </>
    );
}
export default Header;

