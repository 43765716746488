import React from 'react';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { withWidth } from '@material-ui/core';
import './inviteaccepted.css';

function InviteAccepted(props) {

    return (

        <div  className="InviteAcceptedMainContainer">

                <div className="invitecheckIcon">
                        <DoneOutlineIcon />
                </div>

                <h1 className="inviteTitle">Invitation accepted</h1>
                <p  className="inviteDescription">Invitation accepted successfully.</p>

        </div >
    );
}
export default InviteAccepted;

