import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import { TextInputFixedLabel, MediaUploader,} from "@dclab/dc-react-ui-kit";
import './generalinfo.css';
import MediaUpload from '../MediaUpload/MediaUpload';

import replyIcon from '../../assets/replyIcon.svg'
import FileIcon from '../../assets/upload_file_black_24dp.svg'




function GeneralInfo(props) {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [orgName, OrgName] = useState('');
    const [designation,setDesignation] = useState({
        title: ''
    });
    const [imageDetail, setImageDetails] = useState({
        imageName: '',
        imageSrc: ''
    });
    const [companyName, setCompanyName] = useState('');
    const [bio, setBio] = useState('');
    const [notes, setNotes] = useState('');

    useEffect(() => {

        if(props.guestData && Object.keys(props.guestData).length > 0) {
            const {name, bio, companyName, designation, imageMetaData, notes } = props.guestData;
            if(name?.firstName)
                setFirstName(name.firstName);
            if(name?.lastName)
                setLastName(name.lastName);
            setDesignation(designation);
            if(imageMetaData?.imageName && imageMetaData?.imageUrl) {
                setImageDetails({
                    imageName: imageMetaData.imageName,
                    imageUrl: imageMetaData.imageUrl
                });
            }
            setBio(bio);
            setNotes(notes);
            setCompanyName(companyName);
        }
    }, [props.guestData]);


    // function Dataform(e) {
    //     e.preventDefault()
    //     console.warn(FirstName, LastName, OrgName, Designation)
    // }



    const [mediaList, setMediaList] = React.useState([]);

    const mediaObject = [{
        createdAt: "2021-09-23T07:23:40.260Z",
        imageMetaData: {imageUrl: 'https://d2imgltiwla1iy.cloudfront.net/270ba191d372…81819787b27a4812-2366-4ca7-ac5b-020fa357fb85.jpeg', imageName: 'download.jpg', imageThumbnail: 'https://d2imgltiwla1iy.cloudfront.net/270ba191d372…81819787b27a4812-2366-4ca7-ac5b-020fa357fb85.jpeg', fileType: 'image/jpeg', imageSize: 943.15},
        isVideo: false,
        mediaURL: "https://d2imgltiwla1iy.cloudfront.net/270ba191d372c64c70c1ac9f47365a4d-Darul Jannah/1632381819787b27a4812-2366-4ca7-ac5b-020fa357fb85.jpeg",
        orgId: "60acdc878253b30008a83428",
        title: "download.jpg",
        updatedAt: "2021-09-23T07:23:40.260Z",
        _id: "614c2b7c1dc09500086ebec9",
    }]

    const getMediaItems = () => {

        //setMediaList(mediaObject);

    }

    const selectMediaItem = (obj) => {
        console.log("Obj is: ", obj);

    }

    const addMediaItem = (obj) => {
        console.log("Media data is: ", obj);
        props.onChange(obj, 'image');

    }

    const imageData = (obj) => {
        const tempMediaArray = mediaList;
        tempMediaArray.push(obj);
        //setMediaList(tempMediaArray);

    }

    const videoData = (obj) => {
        console.log("Video data is: ", obj);
    }

    const [value, setvalue] = React.useState('');

    return (

        <div className="GeneralinfoMainContainer">


            <Grid container spacing={2}>

                <Grid item md={6} item sm={6} item xs={12}>
                    <TextInputFixedLabel
                        label="First name"
                        labelStatus={true}
                        placeholder="First name"
                        Errormessage="Error message"
                        errortextStatus={false}
                        value= {firstName}
                        onChange={(e) => props.onChange(e.target.value, 'firstName')}
                    />
                </Grid>

                <Grid item md={6} item sm={6} item xs={12}>
                    <TextInputFixedLabel
                        label="Last name"
                        labelStatus={true}
                        placeholder="Last name"
                        Errormessage="Error message"
                        errortextStatus={false}
                        value = {lastName}
                        onChange={(e) => props.onChange(e.target.value, 'lastName')}
                    />
                </Grid>

                <Grid item md={12} item sm={12} item xs={12}>
                    <TextInputFixedLabel
                        label="Org/Institution/Company Name"
                        labelStatus={true}
                        placeholder="Org/Institution/Company Name"
                        count={50}
                        countStatus={true}
                        Errormessage="Error message"
                        errortextStatus={false}
                        value = {companyName}
                        onChange={(e) => props.onChange(e.target.value, 'companyName')}

                    />
                </Grid>

                <Grid item md={12} item sm={12} item xs={12}>
                    <TextInputFixedLabel
                        label="Designation"
                        labelStatus={true}
                        placeholder="Designation"
                        Errormessage="Error message"
                        errortextStatus={false}
                        count={50}
                        countStatus={true}
                        value = {designation.title}
                        onChange={(e) => props.onChange(e.target.value, 'designation')}

                    />
                </Grid>

                <Grid item md={12} item sm={12} item xs={12}>
                    {/* <MediaUpload
                        addMediaItem = {selectMediaItem}
                        label = "Image"
                        buttonName = "ADD IMAGE"
                        typeOfMedia='/images'
                        selectedMedia={{ logoUrl: mediaList?.[0]?.mediaURL, logoName: mediaList?.[0]?.title}}
                        /> */}

                    <MediaUploader
                        mediaList={mediaList}
                        getMediaItems={getMediaItems}
                        showMediaTab={"images"}
                        typeOfMedia='/images'
                        label="Image"
                        onChange={selectMediaItem}
                        addButtonHandler={addMediaItem}
                        imageData={imageData}
                        maxImageLimit={100}
                        videoData={videoData}
                        replyIcon={replyIcon}
                        FileIcon={FileIcon}
                        buttonName="ADD IMAGE"
                        currentImageSize={1500}
                        totalFileUploadLimit={100}
                        selectedMedia={{ logoUrl:imageDetail.imageUrl, logoName: imageDetail.imageName}}
                    />
                
                </Grid>

                <Grid item md={12} item sm={12} item xs={12}>

                    <TextInputFixedLabel
                        label="About Myself/Short bio"
                        labelStatus={true}
                        helpertext="Visible to public users"
                        HelpertextStatus={true}
                        placeholder="Please write e short bio about yourself"
                        Errormessage="Error message"
                        errortextStatus={false}
                        count={600}
                        countStatus={true}
                        tooltipStatus={true}
                        onChange={(e) => props.onChange(e.target.value, 'bio')}
                        value={bio}
                    />
                </Grid>

                <Grid item md={12} item sm={12} item xs={12}>
                        <div style={{paddingTop: "10px"}}>
                    <TextInputFixedLabel
                        label="Note"
                        labelStatus={true}
                        helpertext="Only organization admin can see this"
                        HelpertextStatus={true}
                        placeholder="Special requests or requirements.."
                        Errormessage="Error message"
                        errortextStatus={false}
                        count={300}
                        countStatus={true}
                        tooltipStatus={true}
                        onChange={(e) => props.onChange(e.target.value, 'notes')}
                        value={notes}
                    />
                    </div>
                </Grid>

            </Grid>

        </div >
    );
}
export default GeneralInfo;


