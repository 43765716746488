import axios from 'axios';

const orgEnv = {
    prod: process.env.REACT_APP_BASEURL_PROD_API,
    dev: process.env.REACT_APP_BASEURL_DEV_API
  };
  let currentEnv;
  if (
    window.location.host.includes("amazon") ||
    window.location.host.includes("localhost") ||
    window.location.host.includes("shapla")
  
  ) {
    currentEnv = "dev";
  } else {
    currentEnv = "prod";
  }
  const orgBaseUrl = orgEnv[currentEnv];


export async function getMediaItems(  token , path=''){
      
    if( path.substr(-1) === '/') {
      path = path.substr(0, path.length -1 );
    }
  
    return new Promise((resolve, reject) => {
      axios
        .get(`${orgBaseUrl}/medias${path}` , {
          headers: {
            "Content-Type": "application/json",
            token
          }
        })
        .then((res) => {
          console.log("getMediaItems: ", res);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


export async function uploadMedia(imageMetaData, postData, token) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${orgBaseUrl}/medias/image`, imageMetaData, {
          headers: {
            "Content-Type": "application/json",
            token
          }
        })
        .then((res) => {
          resolve(res);
          // setImageuploaded(false);
          // setUploadButton('importUrlButton');

          // setFiles([]);

          // //image uploading is done, now time to change the below area
          // props.onChange(res);
          // // console.log('hi');

        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  export async function uploadVideo(data, token) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${orgBaseUrl}/medias/video`, data, {
          headers: {
            "Content-Type": "application/json",
            token
          }
        })
        .then((res) => {
          resolve(res);
          // setImageuploaded(false);
          // setUploadButton('importUrlButton');

          // setFiles([]);

          // //image uploading is done, now time to change the below area
          // props.onChange(res);
          // // console.log('hi');

        })
        .catch((error) => {
          reject(error);
        });
    });
  }